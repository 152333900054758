import { useState, useEffect } from 'preact/hooks';

interface ContentType {
  schema: {
    type: string;
  };
}

interface ResponseContent {
  description: string;
  content?: Record<string, ContentType>;  // Media types with content
}

interface DetailResponsesProps {
  responses?: Record<string, ResponseContent>;
  selectedPath: string | null;
  selectedMethod: string | null;
  onResponseChange: (selectedResponses: any[]) => void;
  statusCode: number | null;  // Ensure statusCode is a number
}

export default function DetailResponses({ responses, selectedPath, selectedMethod, onResponseChange, statusCode }: DetailResponsesProps) {
  const [selectedResponses, setSelectedResponses] = useState<any[]>([]);

  useEffect(() => {
    // Reset responses when selectedPath or selectedMethod changes
    const defaultResponses = Object.keys(responses || {}).map((status) => {
      const responseContent = responses![status].content;
      const mediaTypes = responseContent ? Object.keys(responseContent) : [];
      return {
        status,
        description: responses![status].description,
        mediaType: mediaTypes.length > 0 ? mediaTypes[0] : null,
      };
    });
    
    setSelectedResponses(defaultResponses);
    onResponseChange(defaultResponses);
  }, [selectedPath, selectedMethod, responses]);

  const handleResponseSelection = (status: string, description: string, mediaType: string) => {
    const updatedResponses = [...selectedResponses];
    const responseIndex = updatedResponses.findIndex((resp) => resp.status === status);

    if (responseIndex >= 0) {
      updatedResponses[responseIndex] = { ...updatedResponses[responseIndex], description, mediaType };
    } else {
      updatedResponses.push({ status, description, mediaType });
    }

    setSelectedResponses(updatedResponses);
    onResponseChange(updatedResponses);
  };

  return (
    <div class="mt-4">
      <h4 class="font-semibold text-lg md:text-xl text-slate-950 mb-2">Responses</h4>
      {responses ? (
           <div class="overflow-x-auto shadow-md rounded-lg">
          <table class="min-w-full  bg-white  text-sm">
            <thead>
              <tr>
                <th class="py-2 px-4 border-b text-left font-bold">Code</th>
                <th class="py-2 px-4 border-b text-left font-bold">Description</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(responses).map((status, index) => (
                <ResponseRow
                  key={index}
                  status={status}
                  response={responses[status]}
                  onSelectResponse={handleResponseSelection}
                  isEven={index % 2 === 0}
                  statusCode={statusCode}  // Pass statusCode to the row
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p class="text-gray-600 text-sm">No responses available for this endpoint.</p>
      )}
    </div>
  );
}

interface ResponseRowProps {
  status: string;
  response: ResponseContent;
  onSelectResponse: (status: string, description: string, mediaType: string) => void;
  isEven: boolean;
  statusCode: number | null;  // Add statusCode prop
}

function ResponseRow({ status, response, onSelectResponse, isEven, statusCode }: ResponseRowProps) {
  const [selectedMediaType, setSelectedMediaType] = useState<string | null>(null);
  const mediaTypes = response.content ? Object.keys(response.content) : [];

  useEffect(() => {
    if (mediaTypes.length > 0) {
      setSelectedMediaType(mediaTypes[0]);
    }
  }, [mediaTypes]);

  const handleMediaTypeChange = (event: Event) => {
    const selectedValue = (event.target as HTMLSelectElement).value;
    setSelectedMediaType(selectedValue);
    onSelectResponse(status, response.description, selectedValue);
  };

  // Determine row background color based on whether the status matches the statusCode
  const isHighlighted = statusCode === Number(status);  // Convert status to number for comparison

  const rowBackgroundColor = isHighlighted ? 'bg-orange-400 bg-opacity-60' : (isEven ? 'bg-slate-100' : 'bg-slate-200');
  const dropdownBackgroundColor = isEven ? 'bg-slate-200' : 'bg-slate-100';

  return (
    <tr class={`${rowBackgroundColor}`}>
      <td class="py-1.5 px-4 border-b align-top font-semibold">{status}</td>
      <td class="py-1.5 px-4 border-b align-top">
        <div class="mb-2">{response.description || 'No description'}</div>

        {mediaTypes.length > 0 && (
          <div class="relative inline-block text-left">
            <select
              class={`border rounded-xl px-4 py-2  cursor-pointer w-full sm:w-auto ${dropdownBackgroundColor} text-sm sm:text-base`}
              value={selectedMediaType || ''}
              onChange={handleMediaTypeChange}
            >
              <option value="" disabled>Select media type</option>
              {mediaTypes.map((mediaType, index) => (
                <option key={index} value={mediaType}>
                  {mediaType}
                </option>
              ))}
            </select>
          </div>
        )}
      </td>
    </tr>
  );
}
